<template>
    <div>
        <CSDialog
            dialogTitle="退款"
            dialogWidth="960px"
            dialogConfirmBtnText="确定"
            :dialogVisible="refundVisible"
            @onClose="closeModal"
            @onConfirm="refundOrder"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px; font-size: 24px;"
            >
                <div style="padding-bottom: 13px;">
                    <span style="width: 105px; margin-right: 40px;">已付金额</span>
                    <div class="refund-modal-field-content">{{money}}元</div>
                </div>
                <div style="padding-bottom: 13px;">
                    <span style="width: 105px; margin-right: 40px;">退款金额</span>
                    <input
                        :value="money"
                        type="text"
                        placeholder="1-66"
                        readonly
                        style="
                            width: 130px;
                            height: 40px;
                            border-radius: 4px;
                            padding: 0 10px;
                            border-color: #979797;
                            border: 1px solid #999;
                        "
                        class="mr-1"
                    />元
                </div>
                <div>
                    <span style="width: 105px; margin-right: 40px;">退款原因</span>
                    <div class="refund-modal-field-content">
                        <textarea placeholder="限100个字" style="padding: 0 10px;" v-model="refundCause" maxlength="100"></textarea>
                    </div>
                </div>
            </div>
        </CSDialog>
    </div>
</template>

<script>
import {refundMeetingRoomOrderUrl} from "@/requestUrl";
import CSDialog from "@/components/common/CSDialog";

export default {
    name: "refundChargeModal",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        detailId: Number,
        money: Number,
        refreshOrder: Function,
    },
    components: {
        CSDialog,
    },
    data() {
        return {
            refundCause: "",
            refundVisible: false,
        };
    },
    watch:{
        visible(v) {
            this.refundVisible = v;
        }
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        refundOrder() {
            this.$fly.post(refundMeetingRoomOrderUrl, {
                refundCause: this.refundCause,
                regionId: this.$vc.getCurrentRegion().code,
                detailId: this.detailId,
            })
            .then(res => {
                if (res.code !== 0) {
                    return ;
                }
                this.$vc.toast('退款成功');
                this.refundVisible = false;
                this.refreshOrder();
            })
        }
    },
};
</script>

<style lang="stylus" scoped>
.layer
    position fixed
    top 0
    left 0
    width 100%
    height 100%
    background rgba(0, 0, 0, 0.6)
    z-index 2050
    .refund-modal
        position absolute
        top 50%
        left 50%
        transform translateX(-50%) translateY(-50%)
        background #fff
        width 916px
        box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.1)
        border-radius 10px
        &-content
            padding 30px
        &-field
            font-size 0
            &-label
                width 100px
                font-size 24px
                margin-right 50px
                vertical-align top
                color #444
            &-content
                font-size 24px
                vertical-align top
                display inline-block
                textarea
                    width 700px
                    height 200px
                    padding 15px
                    background #F0F0F0
                    border none
                    outline none
                    resize none
                    border-radius 6px
                    &::placeholder
                        color #999999
            &:not(:last-of-type)
                margin-bottom 25px
        &-footer
            height 100px
            text-align center
            border-top 1px solid #979797
            padding 30px 0
            box-sizing border-box
            .btn
                width 80px
                height 40px
                font-size 24px
                color #fff
                border-radius 6px
                line-height 40px
                padding 0
                box-shadow 0 5px 10px 0 rgba(255, 159, 0, 0.3)
                &.cancel
                    background #FF9F00
                &:not(:last-of-type)
                    margin-right 60px
.confirm
    width 80px
    height 40px
    background #1ab394
    box-shadow 0px 5px 10px 0px rgba(0, 182, 148, 0.3)
    border-radius 6px
    border none
    color #fff
    font-size 24px
.cancel
    width 80px
    height 40px
    background #ff9f00
    box-shadow 0px 5px 10px 0px rgba(255, 159, 0, 0.3)
    border-radius 6px
    border none
    color #fff
    margin-right 30px
    font-size 24px
</style>
